@tailwind components;
@tailwind utilities;

.hero-bg {
    background-image: linear-gradient(112deg, white 45%, transparent 60%), url('../public/assets/hero.jpg');
}

@media (max-width: 768px) {
    .hero-bg {
        background-image: url('../public/assets/hero.jpg');
        position: relative;
    }

    .hero-bg::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.1);
    }
}